<template>
	<div class="app-container app-theme-white body-tabs-shadow">
		<div class="app-container">
			<div class="row no-gutters h-screen">
				<div class="col-md-6" :class="{'theme-side': systemMode === 'main'}"
					 :style="GET_COMPANY_SUBSCRIPTION_SETTING.menu_color ? 'background:' +GET_COMPANY_SUBSCRIPTION_SETTING.menu_color : 'background:#181C4C'">
					<div class="p-4 flex flex-col space-y-2">
						<div class="mb-5" v-if="systemMode === 'whitelabel'">
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="img-fluid md:h-auto logo-top md:pl-20 h-12 logo"
									 :src="GET_COMPANY_SUBSCRIPTION_SETTING.full_logo" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ GET_COMPANY_SUBSCRIPTION_SETTING.seller_login_description }}
							</div>
						</div>
						<div class="mb-5" v-else>
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="img-fluid md:h-auto logo-top md:pl-20 h-12 logo"
									 src="./../../assets/images/logo/logo.png" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ $t('welcomeEntry') }}
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="p-4 flex flex-col space-y-2">
						<div class="mb-5">
							<div class="flex md:justify-center align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<div class="font-semibold text-lg md:text-2xl text-blue-900 md:tracking-wide">
									{{ $t('resetPassword') }}
								</div>
							</div>
						</div>
						<div class="flex justify-end md:mt-16">
							<div class="login-border-section w-4/5 pl-5 pr-5">
								<ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
									<form class="mt-10" @submit.prevent="handleSubmit(processReset)">
										<div class="flex flex-col mb-8">
											<label
												class="mb-2 float-left text-sm text-blue-900 dark:text-gray-400 dark:text-gray-400"
												for="email">{{ $t('newPassword') }}</label>
											<ValidationProvider name="newPassword" rules="required|minmax:7,15"
																v-slot="{ errors }">
												<input type="password" v-model="password" class="w-full form-control"
													   required>
												<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
											</ValidationProvider>
										</div>

										<div class="flex flex-col mb-8">
											<label
												class="mb-2 text-sm text-blue-900 dark:text-gray-400 dark:text-gray-400"
												for="email">{{ $t('confirmNewPassword') }}</label>
											<ValidationProvider name="confirmNewPassword" rules="required|minmax:7,15"
																v-slot="{ errors }">
												<input type="password" v-model="confirm" class="w-full form-control"
													   required>
												<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
											</ValidationProvider>
											<!-- <small class="text-red-400 text-sm" v-if="error.email">{{ error.email || null }}</small> -->
										</div>

										<div class="mt-10 flex flex-col space-y-5 mb-5">
											<button type="submit" :disabled="invalid || processing"
													:style="GET_COMPANY_SUBSCRIPTION_SETTING.button_color ? 'background:' +GET_COMPANY_SUBSCRIPTION_SETTING.button_color : 'background:#E8B105'"
													class="w-full login-btn-success px-4 py-2 rounded-md">
												<div class="flex items-center m-auto" v-if="processing">
													<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
														 xmlns="http://www.w3.org/2000/svg" fill="none"
														 viewBox="0 0 24 24">
														<circle class="opacity-25" cx="12" cy="12" r="10"
																stroke="currentColor" stroke-width="4"></circle>
														<path class="opacity-75" fill="currentColor"
															  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
													</svg>
													{{ $t('resettingPassword') }}
												</div>
												<span class="text-white m-auto" v-else>{{ $t('resetPassword') }}</span>
											</button>
											<!-- <p class="text-center text-gray-700 text-sm dark:text-gray-400">Don't have an account? <router-link :to="{ name : 'register' }" class="text-xl hover:text-blue-500">Sign Up</router-link></p> -->
										</div>
									</form>
								</ValidationObserver>
							</div>
						</div>
					</div>
					<div class="container text-center md:bottom-0 md:absolute mb-4 flex justify-center">
						<span class="text-blue-900">Copyright © <span v-if="systemMode === 'whitelabel'">{{ current }} {{
								GET_COMPANY_SUBSCRIPTION_SETTING.brand_name
							}}</span><span
							v-else>{{ current }} B2B Portal</span></span>
						<span>
							<locale-component :show-text="true" :height="5" :width="5"/>
                        </span>
					</div>
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import i18n from '@/plugins/i18n'
import {ValidationProvider, ValidationObserver, localeChanged} from 'vee-validate'
import LocaleComponent from "../../components/LocaleComponent";

export default {
	name: 'resetPassword',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		ValidationProvider,
		ValidationObserver,
		LocaleComponent
	},
	data() {
		return {
			current: new Date().getFullYear(),
			baseURL: window.location.origin,
			token: null,
			password: '',
			confirm: '',
			systemMode: '',
			processing: false
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'USER_TOKEN',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG',
			GET_USER_UTIL: 'GET_USER_UTIL',
			GET_COMPANY_SUBSCRIPTION_SETTING: 'portal/GET_COMPANY_SUBSCRIPTION_SETTING'

		})
	},
	mounted() {
		const {code} = this.$route.query
		if (code) {
			this.token = code
		}
	},
	beforeMount() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
	},
	methods: {
		changeLocale(locale) {
			i18n.locale = locale
			this.$store.commit('utility/CHANGE_LOCALE', locale)
			localeChanged()
		},
		processReset() {
			if (this.password === '' || this.confirm === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.password.length < 6) {
				this.$services.helpers.notification(this.$t('passGreater'), 'error', this)
			} else if (this.password !== this.confirm) {
				this.$services.helpers.notification(this.$t('passwordNotMatch'), 'error', this)
			} else if (!this.token) {
				// console.log(this.token)
				this.$services.helpers.notification(this.$t('invalidUser'), 'error', this)
			} else {
				this.processing = true
				const payload = {
					new_password: this.password,
					reset_code: this.token
				}
				this.$store.dispatch('auth/resetUserPassword', payload)
					.then(_ => {
						this.processing = false
						this.$services.helpers.notification(this.$t('resetSuccessful'), 'success', this)
						setTimeout(() => {
							this.$router.replace({name: 'login'})
						}, 1000)
					})
					.catch(err => {
						this.$services.helpers.notification(err.response.data.message, 'error', this)
					})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

#app {
	overflow-y: hidden !important;
}

.login-head-text {
	font-size: 1.2rem !important;
}

.login-head-text-xl {
	font-size: 1.5rem !important;
}

.override-form-field {
	border: 0;
	border-bottom: 1px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: unset !important;
	border-radius: unset !important;
}

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-control {
	// border: 1px solid $form-border !important;
}

.form-control:focus {
	box-shadow: unset !important;
	// border: 1px solid $theme-primary-color !important;
}

.theme-side {
	background: $production-theme;
}

.login-btn-success {
	background: #E8B105;
}

.logo {
	height: 95px;
}

.login-description {
	position: relative;
	top: 177px;
}

@media only screen and (max-width: 600px) {
	.logo {
		height: 48px;
	}
	.login-description {
		top: 0px
	}
}
</style>
